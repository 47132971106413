export const productCol = [
  {
    label: '商家',
    prop: 'business_name',
    align: 'center'
  },
  // {
  //   label: '商务归属',
  //   prop: 'create_user_name',
  //   align: 'center'
  // },
  {
    label: '产品名',
    prop: 'product_name',
    align: 'center'
  },
  // {
  //   label: '产品描述',
  //   prop: 'description',
  //   align: 'center'
  // },
  {
    label: '产品价格',
    prop: 'price',
    align: 'center'
  },
  {
    label: '创建人',
    prop: 'create_manager_name',
    align: 'center'
  },
  {
    label: '创建时间',
    prop: 'create_time',
    align: 'center'
  },
  {
    label: '更新人',
    prop: 'update_manager_name',
    align: 'center'
  },
  {
    label: '更新时间',
    prop: 'update_manager_time',
    align: 'center'
  },
  {
    label: '商家操作人',
    prop: 'update_user_name',
    align: 'center'
  },
  {
    label: '商家更新时间',
    prop: 'update_time',
    align: 'center'
  },
  {
    label: '操作',
    slot:'operation'
  },
]

export default {
  productCol
}