<template>
  <div class="productList">
    <div>
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="140px"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="商家名称：">
              {{business_name}}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="产品名称：">
              <el-input
                v-model="searchForm.product_name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <div>
            <el-button type="primary" size="small" @click="serchData"
              >查询</el-button
            >
          </div>
        </el-row>
      </el-form>
    </div>
    <div class="derive">
      <el-button
        size="small"
        @click="createProduct()"
        v-if="managerType != 0 || getAuth('productOperate')"
        >添加商家产品</el-button
      >
    </div>
    <div>
      <base-table
        :columns="productCol"
        :data="tableData"
        :pagination="searchForm"
        :total="total"
        @getData="initData"
        :loading="loading"
        :isPaginationShow="true"
        wrapperHeight="calc(100% - 45px)"
      >
        <el-table-column
          slot="operation"
          label="操作"
          align="center"
          fixed="right"
          v-if="managerType != 0 || getAuth('productOperate')"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="editProduct(scope.row)"
              >修改</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="delProduct(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </base-table>
    </div>
    <productModal ref="productModal" />
  </div>
</template>

<script>
import columns from "./columns";
import productModal from "./components/product-modal.vue";
import { listProduct, statusProduct } from "@/api/product.js";
import { getAuth } from "@/utils/index.js";
export default {
  name: "productList",
  components: { productModal },
  data() {
    return {
      managerType: localStorage.getItem("managerType"),
      getAuth: getAuth,
      ...columns,
      tableData: [],
      total: 0,
      loading: false,
      searchForm: {
        page: 1,
        per_page: 10,
        business_id: "",
      },
      business_id: "",
      business_name: "",
    };
  },

  mounted() {
    var business_id = this.$route.query.business_id;
    var business_name = this.$route.query.business_name;
    if (business_id) {
      this.searchForm.business_id = business_id;
      this.business_id = business_id;
      this.business_name = business_name;
    }
    this.initData();
  },

  methods: {
    serchData(){
      this.searchForm.page = 1
      this.searchForm.per_page = 10
      this.initData()
    },
    async initData() {
      const { code, data } = await listProduct(this.searchForm);
      if (code == 200) {
        this.tableData = data.data;
        this.total = data.total;
      }
    },
    createProduct() {
      this.$refs.productModal.isShow(
        "add",
        "",
        this.business_id,
        this.business_name
      );
    },
    editProduct(row) {
      this.$refs.productModal.isShow(
        "edit",
        row,
        this.business_id,
        this.business_name
      );
    },
    delProduct(row) {
      this.$confirm("是否删除当前产品？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const parmes = {
            product_id: row.product_id,
            status: 2,
          };
          const { code } = await statusProduct(parmes);
          if (code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.initData();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.productList {
  .derive {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}
</style>